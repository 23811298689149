import { CategoryTitle, FlexContainer } from '@cheddartv/storybook-news'

import React from 'react'

import AdobePdfViewer from '../ComponentMap/AdobePdfViewer'
import VideoEmbed from '../ComponentMap/VideoEmbed'
import StoryList from '../Story/RelatedStories/StoryList'
import PlatformsList from '../WhereToWatch/PlatformsList'
import VideoPlayer from './VideoPlayer'

import parse from 'html-react-parser'
import sanitize from 'sanitize-html'
import { decodeHTML } from 'transformer'

export const sanitizedHtml = html => {
  //setting allowedTags and allowedAttributes to false adds the `script` and `style` tags
  //which is a security vulnerability
  //so that we don't have a million logs complaining about this exposure
  //we set allowVulnerableTags to true to acknowledge the existence of the vulnerability
  //more details here: https://www.npmjs.com/package/sanitize-html
  return sanitize(html, { allowedTags: false, allowedAttributes: false, allowVulnerableTags: true })
}

export const hasElement = ({ html, name }) => {
  return html.indexOf(name) !== -1
}

const renderHtml = html => {
  let index = 0
  const options = {
    replace: domNode => {
      index++
      switch (domNode?.attribs?.class) {
        case 'article-body-video':
          return <VideoEmbed key={`article-body-video-${index}`} index={index} node={domNode} />

        case 'body-video-asset':
          return (
            <>
              <VideoPlayer
                autoplay={false}
                muted={false}
                isLive={false}
                src={domNode?.attribs?.['data-src']}
                title={domNode?.attribs?.['data-title']}
                type={domNode?.attribs?.['data-type']}
              />
              <div className='caption'>{domNode?.attribs?.['data-description']}</div>
            </>
          )

        case 'embed pdf-embed':
          return <AdobePdfViewer index={index} key={`pdf-embed-${index}`} url={domNode?.attribs?.['data-url']} />

        case 'platforms-list-embed':
          const platformsList =
            domNode.children
              ?.filter(child => child?.children && child?.name === 'div')
              ?.map(platform => ({
                id: platform?.attribs?.['data-name'],
                href: platform?.attribs?.['data-url'],
                backgroundColor: platform?.attribs?.['data-background'],
                backgroundColorHover: platform?.attribs?.['data-backgroundhover'],
                logo: platform?.attribs?.['data-logo'],
                logoHover: platform?.attribs?.['data-logohover']
              })) ?? []

          return (
            platformsList?.length > 0 && (
              <FlexContainer alignItems='center' direction='column' margin='0 0 1rem !important' gap='1rem'>
                <CategoryTitle uppercased tag='h2'>
                  {domNode.children?.find(child => child?.name === 'p')?.children?.[0]?.data ?? ''}
                </CategoryTitle>
                <PlatformsList platforms={platformsList} />
              </FlexContainer>
            )
          )

        case 'story-list':
          return <StoryList domNode={domNode} />

        case 'instagram-embed':
        case 'city-spark-embed':
          return (
            <div
              key={index}
              style={{ marginBottom: '30px', display: 'flex', justifyContent: 'center' }}
              dangerouslySetInnerHTML={{ __html: decodeHTML(domNode?.attribs?.['data-embed']) }}
            />
          )

        default:
          return domNode
      }
    }
  }

  return parse(html, options)
}

export default renderHtml
